<template>
  <v-dialog v-model="dialog"
              width="950px"  transition="dialog-bottom-transition" scrollable>
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>Exam enrolled</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="6" v-if="oldProductDetail">
              <b>Enroled exam:</b>  {{oldProductDetail.name}} <br/>
              <b>Subject Code:</b>  {{oldProductDetail.subject_code ? oldProductDetail.subject_code : '-'}}<br/>
              <b>Category:</b>   {{oldProductDetail.category_name ? oldProductDetail.category_name : 'NA' }}<br/>
              <b>Instrument Family:</b>  {{oldProductDetail.instrument_name ? oldProductDetail.instrument_name : 'NA' }}<br/>
              <b>Syllabus: </b> {{oldProductDetail.syllabus_name ? oldProductDetail.syllabus_name : 'NA'}}<br/>
              <b>Grade: </b> {{oldProductDetail.grade_name ? oldProductDetail.grade_name : '-'}}<br/>
              <b>Location: </b> {{locationDetail ? locationDetail.name : '-'}}<br/>

              <b>Exam type: </b> {{oldProductDetail.exam_type_name ? oldProductDetail.exam_type_name : 'NA' }}
            </v-col>

            <v-col cols="12" md="6" v-if="productDetail && oldProductDetail.id != productDetail.id">
              <b>Enroled exam:</b>  {{productDetail.name}} <br/>
              <b>Subject Code:</b>  {{productDetail.subject_code ? productDetail.subject_code : '-'}}<br/>
              <b>Category:</b>   {{productDetail.category_name ? productDetail.category_name : 'NA' }}<br/>
              <b>Instrument Family:</b>  {{productDetail.instrument_name ? productDetail.instrument_name : 'NA' }}<br/>
              <b>Syllabus: </b> {{productDetail.syllabus_name ? productDetail.syllabus_name : 'NA'}}<br/>
              <b>Grade: </b> {{productDetail.grade_name ? productDetail.grade_name : '-'}}<br/>
              <b>Location: </b> {{locationDetail ? locationDetail.name : '-'}}<br/>

              <b>Exam type: </b> {{productDetail.exam_type_name ? productDetail.exam_type_name : 'NA' }}
            </v-col>
          </v-row>
          <v-row class="text-center">
            <v-col cols="12" md="12" >
              <v-switch @change="changeSubjectCode()" @blur="changeSubjectCode()"  v-model="subjectCodeSearch" label="Search by subject code or exam name"></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12">
              <v-row v-if="subjectCodeSearch">
                <v-col cols="12" md="12">
                    <v-text-field append-icon="mdi-magnify"  v-on:keyup.enter="searchBySubjectCode" dense outlined v-model="subject_code" :error="subjectCodeStatus=='ERROR'" :success="subjectCodeStatus=='SUCCESS'" label="Enter subject code..." clearable>
                    </v-text-field>
                    <div v-bind:class="{'text-danger': subjectCodeStatus==='ERROR' }" v-if="subject_messages && subjectCodeStatus==='ERROR'">{{subject_messages}}</div>
                    <span class="text-danger" v-if="$v.subject_code.$error">This information is required</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-autocomplete @change="getAllExamSession" :class="{ 'input--error-enrolment': $v.session.enrolment_year_id.$error}" :error="$v.session.enrolment_year_id.$error" dense outlined label="Select enrolment year" v-model="session.enrolment_year_id" :items="enrolment_years" item-value="id" item-text="year">
                  </v-autocomplete>
                  <span class="text-danger" v-if="$v.session.enrolment_year_id.$error">This information is required</span>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete :class="{ 'input--error-enrolment': $v.exam.location_id.$error}" :error="$v.exam.location_id.$error"  @change="changeLocation()" dense outlined label="Select location" v-model="exam.location_id" :items="locations" item-value="id" item-text="name"></v-autocomplete>
                  <span class="text-danger" v-if="$v.exam.location_id.$error">This information is required</span>
                </v-col>
              </v-row>
              <v-row v-if="!subjectCodeSearch">
                <v-col cols="12" md="6">
                  <v-autocomplete  :error="$v.exam.category_id.$error"  @change="changeCategory(true)" :loading="categoryLoading" dense outlined label="Category" v-model="exam.category_id" :items="categories" item-value="id" item-text="name"></v-autocomplete>
                  <span class="text-danger" v-if="$v.exam.category_id.$error">This information is required</span>
                </v-col>
                <v-col v-if="instrument_flag" cols="12" md="6" >
                  <v-autocomplete  :error="$v.exam.instrument_id.$error" @change="changeInstrument(true)" :loading="instrumentLoading" dense outlined label="Instrument" v-model="exam.instrument_id" :items="instruments"  item-value="id" item-text="name">
                  </v-autocomplete>
                  <span class="text-danger" v-if="$v.exam.instrument_id.$error">This information is required</span>
                </v-col>
                <v-col cols="12" md="6" v-if="syllabus_flag">
                  <v-autocomplete :error="$v.exam.syllabus_id.$error"  @change="changeSyllabus(true)" :loading="syllabusLoading" dense outlined label="Syllabus" v-model="exam.syllabus_id" :items="syllabuses" item-value="id" item-text="name" ></v-autocomplete>
                  <span class="text-danger" v-if="$v.exam.syllabus_id.$error">This information is required</span>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete :error="$v.exam.grade_id.$error" @change="changeGrade(true)" dense outlined label="Select grade" v-model="exam.grade_id" :items="grades" item-value="id" item-text="name"></v-autocomplete>
                  <span class="text-danger" v-if="$v.exam.grade_id.$error">This information is required</span>
                </v-col>
                <v-col cols="12" md="6" v-if="exam_type_flag">
                  <v-autocomplete :error="$v.exam.exam_type_id.$error"  @change="changeExamType(true)" dense outlined label="Select exam type" v-model="exam.exam_type_id" :items="exam_types" item-value="id"  item-text="name"></v-autocomplete>
                  <span class="text-danger" v-if="$v.exam.exam_type_id.$error">This information is required</span>
                </v-col>
                <v-col cols="12" md="6" v-if="exam_option_flag">
                  <v-autocomplete :error="$v.exam.exam_option_id.$error"  @change="changeExamOption(true)" dense outlined label="Select exam option" v-model="exam.exam_option_id" :items="exam_options"  item-value="id" item-text="name"></v-autocomplete>
                  <span class="text-danger" v-if="$v.exam.exam_option_id.$error">This information is required</span>
                </v-col>
                <v-col cols="12" md="6" v-if="categoryDetail && categoryDetail.enable_video_delivery_mode">
                    <v-select @change="changeExamDeliveryMethods" dense outlined label="Exam delivery method" v-model="exam.exam_delivery_method" :items="exam_delivery_methods" item-value="value" item-text="name" :error="$v.exam.exam_delivery_method.$error">
                    </v-select>
                    <span class="text-danger" v-if="$v.exam.exam_delivery_method.$error">This information is required</span>
                  </v-col>

              </v-row>
              <v-row>
                <v-col cols="12" md="6" v-if="productDetail && productDetail.requires_hand_selection">
                  <v-select :class="{ 'input--error-enrolment': $v.exam.hand_type.$error}" :error="$v.exam.hand_type.$error" v-model="exam.hand_type" dense outlined label="Select right or left handed" item-value="value" item-text="text" :items="hands" >
                    <template v-slot:label>
                      Select Right or Left Handed<span class="text-danger">*</span>
                    </template>
                  </v-select>
                  <span class="text-danger" v-if="$v.exam.hand_type.$error">This information is required</span>
                </v-col>
                <v-col cols="12" md="6" v-if="productDetail && productDetail.requires_guitar_selection">
                  <v-select :error="$v.exam.guitar_selection.$error"  v-model="exam.guitar_selection" dense outlined label="Select guitar selection" :items="guitar_selection" >
                    <template v-slot:label>
                      Select Guitar Selection <span class="text-danger">*</span>
                    </template>
                  </v-select>
                  <span class="text-danger" v-if="$v.exam.guitar_selection.$error">This information is required</span>
                </v-col>
                <v-col cols="12" md="6mn."  v-if="productDetail && productDetail.requires_drum_selection">
                  <v-select :error="$v.exam.drum_selection.$error"  v-model="exam.drum_selection" dense outlined label="Select drum selection"
                            :items="drum_selection">
                    <template v-slot:label>
                      Select Drum Selection <span class="text-danger">*</span>
                    </template>
                  </v-select>
                  <span class="text-danger" v-if="$v.exam.drum_selection.$error">This information is required</span>
                </v-col>
                <v-col cols="12" v-if="productDetail && productDetail.requires_drum_selection && exam.drum_selection==='electric'">
                  <label >Are you happy for electric drum kit?</label>
                  <v-radio-group v-model="exam.can_consider_electric_drum_kit" row v-if="productDetail && productDetail.requires_drum_selection && exam.drum_selection==='electric'">

                    <v-radio
                        label="Yes"
                        value="1"
                    ></v-radio>
                    <v-radio
                        label="No"
                        value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row v-if="productDetail">
                <v-col cols="12" md="12">Exam session</v-col>
                <v-col cols="12" md="12">
                  <v-switch @change="viewClosedExam" v-model="view_closed_exam"  label="View closed exam sessions"></v-switch>
                </v-col>
                <v-col cols="12" md="12">
                    <div class="title"> Available session</div>
                    <div class="alert" v-if="exam_sessions.length > 0" v-for="(item,index) in exam_sessions" v-bind:class="checkExamSessionClass(item.enrolment_stage)">
                      <input  @click="changeExamSession(item.id)"  type="radio" v-model="session.exam_session_id" :value="item.id">
                      <span class="ml-3 font-medium">{{ item.name }}</span> <br/>
                      <span class="ml-8 font-medium">{{ item.formatted_start_date }} - {{ item.formatted_end_date }}</span> <br/>
                      <span class="ml-8 font-medium" v-if="item.formatted_closing_date"><b>Closing date:</b> {{ item.formatted_closing_date }}</span><br/>
                      <span class="ml-8 font-medium" v-if="item.late_closing_fee && item.enrolment_stage=='running_late'"><b>Late fee:</b> {{currency}}{{item.late_closing_fee}}</span><br/>
                      <span class="ml-8 font-medium" v-if="item.late_closing_date_format && item.enrolment_stage=='running_late'"><b>Late closing date:</b> {{item.late_closing_date_format }}</span>
                    </div>
                    <div v-if="exam_sessions.length ===0">No exam sessions available to continue</div>
                    <span class="text-danger" v-if="$v.session.exam_session_id.$error">This information is required</span>
                  </v-col>
                <v-col cols="12" md="12" v-if="scoreDetail && scoreDetail.enable_private_venues && examSessionDetail && examSessionDetail.enable_private_venue">
                  <h6 class="mb-2 mt-5">
                    Enter private venue code (if any)
                  </h6>
                  <v-text-field :class="{ 'input--error-enrolment': $v.venueDetail.$error}" outlined dense placeholder="Enter venue code" label="Enter venue code" :success="venueStatus=='SUCCESS'" :error="venueStatus=='ERROR' || $v.venueDetail.$error"
                                v-model="venue_code" @click="verifyVenueCode">
                  </v-text-field>
                  <span class="text-danger" v-if="$v.venueDetail.$error">This information is required</span>
                  <div v-bind:class="{'text-success':venueStatus==='SUCCESS', 'text-danger': venueStatus==='ERROR' }" v-if="venue_messages!=null">
                    {{venue_messages}}
                  </div>
                  <div v-if="venueDetail">
                    <span><b>Venue name:</b> {{ venueDetail.name}}</span> <br>
                  </div>
                </v-col>
                <v-col cols="12" md="12" v-if="scoreDetail && scoreDetail.enable_private_venues && examSessionDetail && !examSessionDetail.enable_private_venue">
                  <v-col cols="12" md="12">
                    <label>Are you happy to consider a private venue for this enrolment ?
                    </label>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-radio-group
                        v-model="session.can_consider_private_venues"
                        row
                    >
                      <v-radio
                          label="Yes"
                          value="1"
                      ></v-radio>
                      <v-radio
                          label="No"
                          value="0"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-col>
              </v-row>
              
              <v-row v-if="productDetail && productDetail.require_recorded_accompaniment">
                <v-col cols="12">
                  <v-switch 
                    v-model="require_recorded_accompaniment" 
                    label="I intend to use recorded accompaniment for this exam"></v-switch>
                </v-col>
              </v-row>
              <v-row v-if="productDetail && productDetail.require_teaching_grade">
                <v-col cols="12">
                  <v-select
                    outlined
                    dense
                    v-model="teaching_grades"
                    :items="teachingGrades"
                    item-text="name"
                    item-value="value"
                    label="Teaching grade"
                  ></v-select>
                </v-col>
              </v-row>

            </v-col>


          </v-row>

        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text class="cancel-btn"
                x-large
                @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn v-if="productDetail && candidate && candidate.exam_status!=='scheduled'"
               color="btn btn-primary" dark x-large
            @click="createOrUpdate()" :loading="loading">
          Save
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import LocationService from "@/services/practical-exam/location/LocationService";
import CategoryService from "@/services/product/category/CategoryService";
import InstrumentService from "@/services/product/instrument/InstrumentService";
import SyllabusService from "@/services/product/syllabus/SyllabusService";
import GradeService from "@/services/product/grade/GradeService";
import ExamTypeService from "@/services/product/exam-type/ExamTypeService";
import ExamOptionService from "@/services/product/exam-option/ExamOptionService";
import ProductService from "@/services/product/ProductService";
import AccountHolderService from "@/services/user/accountholder/AccountholderService";
import ScoreService from "@/services/score/score/ScoreService";
import CandidateEnrolmentInfoService from "@/services/candidate/enrolment-info/CandidateEnrolmentInfoService";
import ExamSession from "@/services/practical-exam/exam-session/ExamSession";
import CandidateService from "@/services/candidate/CandidateService";
import {email, required, requiredIf} from "vuelidate/lib/validators";

import EnrolmentYearService from "@/services/setting/enrolment-year/EnrolmentYearService";
import VenueService from "@/services/practical-exam/venue/VenueService";

const venue=new VenueService();
const enrolmentYear=new EnrolmentYearService();

const candidate=new CandidateService();
const examSession=new ExamSession();
const candidateEnrolmentInfo=new CandidateEnrolmentInfoService();
const score=new ScoreService();
const accountHolder=new AccountHolderService();
const product=new ProductService();
const examOption=new ExamOptionService();
const examType=new ExamTypeService();
const grade=new GradeService();
const syllabus=new SyllabusService();
const instrument=new InstrumentService();
const category=new CategoryService();
const location=new LocationService();
export default {
  data(){
    return {
      exam_delivery_methods: [
        { name: 'Face to Face', value:'face_to_face' },
        { name: 'Video', value:'video' },
      ],
      dialog:false,
      edit:false,
      scoreId:'',
      locations:[],
      locationDetail:{},
      exam:{
        location_id:'',
        category_id:'',
        instrument_id:'',
        syllabus_id:'',
        grade_id:'',
        exam_type_id:'',
        exam_option_id:'',
        hand_type:'',
        guitar_selection:'',
        drum_selection:'',
        collaborative_instrument:'',
        can_consider_electric_drum_kit:0,
        exam_delivery_method:'',
      },
      session:{
        exam_session_id:'',
        enrolment_year_id:'',
        can_consider_private_venues:'',
      },
      require_recorded_accompaniment: 0,
      exam_request:{

      },
      categories:[],
      instruments:[],
      syllabuses:[],
      grades:[],
      exam_options:[],
      exam_types:[],
      grade_ids:[],
      scheduling_requests:[],
      teachingGrades:[
        {
            name: 'Preliminary to Grade 2',
            value: 'preliminary_to_grade_2'
        },
        {
            name: 'Grade 3 - 4',
            value: 'grade_3_to_4'
        },
        {
            name: 'Grade 5 - 6',
            value: 'grade_5_to_6'
        },
        {
            name: 'Grade 7 - 8',
            value: 'grade_7_to_8'
        },
      ],
      loading:false,
      categoryLoading:false,
      instrumentLoading:false,
      syllabusLoading:false,
      gradeLoading:false,
      examTypeLoading:false,
      examOptionLoading:false,

      instrument_flag:false,
      syllabus_flag:false,
      exam_type_flag:false,
      exam_option_flag:false,

      candidate_enrolment_info:{},
      accountHolderDetail:{},
      scoreDetail:{},
      categoryDetail: {},
      instrumentDetail: {},
      syllabusDetail: {},
      gradeDetail: {},
      examTypeDetail: {},
      examOptionDetail: {},
      productDetail: {},
      oldProductDetail: {},

      product_overlay:false,
      candidate_overlay:false,
      old_product_price:'',
      product_price:'',
      hands:[
        {value:'left',text:'Left Handed'},
        {value:'right',text:'Right Handed'},
      ],
      guitar_selection:[
        {value:'electric',text:'Acoustic or electric kit ok'},
        {value:'acoustic',text:'Acoustic kit only'},
      ],
      drum_selection:[
        {value:'electric',text:'Electric Drum'},
        {value:'acoustic',text:'Acoustic Drum'},
      ],
      exam_sessions:[],
      candidateId:'',
      view_closed_exam:false,
      candidate:{},
      enrolment_years:[],
      display_remarks_types:[
        {value:'admin',text:'Admin'},
        {value:'enroller',text:'Enroller'},
      ],
      examSessionDetail:null,
      venue_code:null,
      venueDetail:null,
      venue_messages:null,
      venueStatus:null,
      currency:null,
      subjectCodeSearch:false,
      subject_code:null,
      subject_messages:'',
      subjectCodeStatus:null,
      teaching_grades: ''
    }
  },
  validations(){
    return this.rules;
  },
  methods:{
    changeExamDeliveryMethods(){
      this.getProductDetail();
    },
    getAllEnrolmentYears() {
      enrolmentYear
          .all()
          .then(response =>{
            this.enrolment_years=response.data;
          })
          .catch(err => {

          }).finally(() => {

      });
    },
    openDialog(candidate){
      if(candidate.owner_id){
        this.subjectCodeSearch=false;
        this.candidate=candidate;
        this.$v.$reset();
        this.candidateId=candidate.id;
        this.getAccountHolderDetail(candidate.owner_id);
        this.getOldProductData(candidate.product_id);
        this.findCandidateEnrolmentInfo(this.candidateId);
        this.getAllEnrolmentYears();

      }else{
        this.$snotify.error("Sorry this doesn't belong to AccountHolder");
      }

    },
    closeDialog(){
      this.dialog=false;
      this.emptyFields('all');
    },
    findCandidateEnrolmentInfo(candidateId){
      candidateEnrolmentInfo
          .findByCandidateId({'candidate_id':candidateId})
          .then((response)=>{
            this.candidate_enrolment_info=response.data.enrolment_info;

            if(this.candidate_enrolment_info.location_id){
              this.exam.location_id=this.candidate_enrolment_info.location_id;
              this.findLocationDetail();
              this.getAllCategories();
            }
            if(!this.candidate_enrolment_info.location_id){
              this.getAllCategories();
            }
            if(this.candidate_enrolment_info.guitar_selection){
              this.exam.guitar_selection=this.candidate_enrolment_info.guitar_selection;
            }
            if(this.candidate_enrolment_info.drum_selection){
              this.exam.drum_selection=this.candidate_enrolment_info.drum_selection;
            }
            if(this.candidate_enrolment_info.hand_type){
              this.exam.hand_type=this.candidate_enrolment_info.hand_type;
            }
            
            if(this.candidate_enrolment_info && this.candidate_enrolment_info.teaching_grades){
              this.teaching_grades = this.candidate_enrolment_info.teaching_grades;
            }else{
              this.teaching_grades = '';
            }

            if(this.candidate_enrolment_info.require_recorded_accompaniment){
              this.require_recorded_accompaniment = 1;
            }else{
              this.require_recorded_accompaniment = 0;
            }

            if(this.candidate_enrolment_info.unsuitable_examiner_comments){
              this.exam_request.unsuitable_examiner_comments=this.candidate_enrolment_info.unsuitable_examiner_comments;
            }
            if(this.candidate_enrolment_info.can_consider_electric_drum_kit){
              this.exam.can_consider_electric_drum_kit=this.candidate_enrolment_info.can_consider_electric_drum_kit.toString();
            }
            if(this.candidate_enrolment_info.enrolment_year_id){
              this.session.enrolment_year_id=this.candidate_enrolment_info.enrolment_year_id;
            }

            if(this.candidate_enrolment_info.venue_code){
              this.venue_code=this.candidate_enrolment_info.venue_code;
              this.verifyVenueCode();
            }
            if(this.candidate_enrolment_info.exam_delivery_method){
              this.exam.exam_delivery_method=this.candidate_enrolment_info.exam_delivery_method;
            }
            if(this.candidate_enrolment_info.exam_session_id){
              this.session.exam_session_id=this.candidate_enrolment_info.exam_session_id;
              this.getExamSessionDetail(this.candidate_enrolment_info.exam_session_id,true);
            }
            if(this.candidate_enrolment_info.can_consider_private_venues){
              this.session.can_consider_private_venues="1";
            }else{
              this.session.can_consider_private_venues="0";
            }
          })
          .catch(err => {

          }).finally(() => {

        });
    },
    getOldProductData(productId){
      product
          .show(productId)
          .then((response)=>{
              this.oldProductDetail=response.data.product;
              if(this.oldProductDetail.category_id){
                this.exam.category_id=this.oldProductDetail.category_id;
                this.changeCategory(false);

              }
              if(this.oldProductDetail.instrument_id){
                this.exam.instrument_id=this.oldProductDetail.instrument_id;
                this.changeInstrument(false);
              }
              if(this.oldProductDetail.syllabus_id){
                this.exam.syllabus_id=this.oldProductDetail.syllabus_id;
                this.changeSyllabus(false);
             }
            if(this.oldProductDetail.grade_id){
              this.exam.grade_id=this.oldProductDetail.grade_id;
              this.changeGrade(false);
            }
            if(!this.oldProductDetail.exam_type_id && !this.oldProductDetail.exam_option_id){
              if(!this.categoryDetail.enable_video_delivery_mode && !this.exam.exam_delivery_method) {
                this.getProductDetail();
              }
            }
            if(this.oldProductDetail.exam_type_id){
              this.exam.exam_type_id=this.oldProductDetail.exam_type_id;
              this.changeExamType(false);
            }
            if(this.oldProductDetail.exam_option_id){
              this.exam.exam_option_id=this.oldProductDetail.exam_option_id;
              this.changeExamOption(false);
            }else{
              if(!this.categoryDetail.enable_video_delivery_mode && !this.exam.exam_delivery_method) {
                this.getProductDetail();
              }
            }

            if(this.oldProductDetail.exam_delivery_method){
              this.getProductDetail();
            }

            this.getOldProductPriceByScore();

          })
          .catch(err => {

          }).finally(() => {
              this.dialog=true;
          })
    },
    getOldProductPriceByScore(){
      let data={};
      if(this.oldProductDetail){
        data['product_id']=this.oldProductDetail.id;
        data['score_id']=this.scoreId;
        product
            .getProductPriceByScore(data)
            .then((response) => {
              this.old_product_price=response.data.product_price;
            })
            .catch(err => {

            });
      }
    },
    //AccountHolder
    getAccountHolderDetail(accountHolderId){
      accountHolder
          .show(accountHolderId)
          .then((response) => {
            this.accountHolderDetail=response.data.accountHolder;
            if(this.accountHolderDetail){
              this.scoreId=this.accountHolderDetail.current_score_id;
              this.findScoreDetail();
            }
          })
          .catch(err => {

          }).finally(() => {
        this.categoryLoading = false;
      });
    },
    //Score
    findScoreDetail(){
      if(this.scoreId){
        score
            .show(this.scoreId)
            .then((response) => {
              this.scoreDetail=response.data.score;
              this.currency=this.scoreDetail.currency_symbol;
              this.getAllLocation();
              /*this.validationColourForTeacherInformation();*/
            })
            .catch(err => {

            }).finally(() => {

        });
      }
    },
    //Location
    getAllLocation(){
      location
          .all({'score_id':this.scoreId})
          .then((response) => {
            this.locations=response.data.locations;
          })
          .catch(err => {

          })
          .finally(() => (this.isLoading = false))
    },

    changeLocation(){
      /*this.emptyFields('location');*/
      this.getAllCategories();
      /*if(!this.edit){
        this.emptyFields('location');
      }*/
      this.getAllExamSession();
    },
    findLocationDetail(){
      location
          .show(this.exam.location_id)
          .then((response) => {
            this.locationDetail=response.data.location;
          })
          .catch(err => {

          })
          .finally(() => (this.isLoading = false))
    },
    //Category
    getAllCategories(){
      let data={};
      this.categories=[];
      this.categoryLoading=true;
      category
          .getFilterData(data)
          .then((response) => {
            this.categories=response.data.categories;
          })
          .catch(err => {

          })
          .finally(() => {
            this.categoryLoading = false;
          });
    },
    changeCategory(emptyField=true){
      this.findCategoryDetail(this.exam.category_id);
      if(emptyField){
        this.emptyFields('category');
      }
    },
    findCategoryDetail(categoryId){
      category
          .show(categoryId)
          .then((response) => {
            this.categoryDetail=response.data.category;
            if(this.categoryDetail && this.categoryDetail.has_instruments){
              this.instrument_flag=true;
              this.getAllInstruments();
            }else{
              this.instrument_flag=false;
            }
            if(this.categoryDetail && !this.categoryDetail.has_instruments && this.categoryDetail.has_syllabuses){
              this.exam.instrument_id=null;
              this.syllabus_flag=true;
              this.getAllSyllabus();
            }else{
              this.syllabus_flag=false;
            }
            if(this.categoryDetail && !this.categoryDetail.has_instruments && !this.categoryDetail.has_syllabuses){
              this.exam.instrument_id=null;
              this.exam.syllabus_id=null;
              this.grade_ids=this.categoryDetail.applicable_grades;
              this.getAllGrades();
            }

          })
          .catch(err => {

          });
    },
    //Instrument
    getAllInstruments(){
      let data={};
      data['category_id']=this.exam.category_id;
      if(this.scoreId){
        data['score_id']=this.scoreId
      }
      this.instruments=[];
     /* this.exam.instrument_id=null;*/
      this.instrumentLoading=true;
      instrument
          .getFilterData(data)
          .then((response) => {
            this.instruments=response.data.instruments;
          })
          .catch(err => {

          })
          .finally(() => {
            this.instrumentLoading = false;
          });
    },
    changeInstrument(emptyField=true){
      this.findInstrumentDetail(this.exam.instrument_id);
      if(emptyField){
        this.emptyFields('instrument');
      }
    },
    findInstrumentDetail(instrumentId){
      instrument
          .show(instrumentId)
          .then((response) => {
            this.instrumentDetail=response.data.instrument;
            if(this.instrumentDetail && this.instrumentDetail.has_syllabuses && this.categoryDetail && this.categoryDetail.has_syllabuses){
              this.syllabus_flag=true;
              this.getAllSyllabus();

            }else{
              this.syllabus_flag=false;
            }
            if(this.instrumentDetail && !this.instrumentDetail.has_syllabuses){
              this.grade_ids=this.instrumentDetail.applicable_grades;
              this.getAllGrades();
            }


          })
          .catch(err => {

          })
          .finally(() => {

          });
    },
    //Syllabus
    getAllSyllabus(){
      this.syllabuses=[];
      this.syllabusLoading=true;
      /*this.exam.syllabus_id=null;*/
      syllabus
          .getFilterData({'category_id':this.exam.category_id,'instrument_id':this.exam.instrument_id,'score_id':this.scoreId})
          .then((response) => {
            this.syllabuses=response.data.syllabuses;
          })
          .catch(err => {

          })
          .finally(() => {
            this.syllabusLoading = false;
          });
    },
    findSyllabusDetail(syllabusId){
      syllabus
          .show(syllabusId)
          .then((response) => {
            this.syllabusDetail=response.data.syllabus;
            this.grade_ids=this.syllabusDetail.applicable_grades;
            this.getAllGrades();
          })
          .catch(err => {

          });
    },
    changeSyllabus(emptyField=true){
      this.findSyllabusDetail(this.exam.syllabus_id);
      if(emptyField){
        this.emptyFields('syllabus');
      }
    },
    //Grade
    getAllGrades(){
      if(this.grade_ids){
        this.grades=[];
       /* this.exam.grade_id=null;*/
        this.gradeLoading=true;
        grade
            .getGradeByIds({'grade_ids':this.grade_ids})
            .then((response) => {
              this.grades=response.data.grades;
            })
            .catch(err => {

            }).finally(() => {
          this.gradeLoading = false;
        });
      }

    },
    changeGrade(emptyField=true){
      this.checkExamTypeEnabled();
      if(emptyField){
        this.emptyFields('grade');
      }
    },
    //Exam Type
    checkExamTypeEnabled(){
      let data={}
      if(this.exam.category_id){
        data['category_id']=this.exam.category_id;
      }
      if(this.exam.instrument_id){
        data['instrument_id']=this.exam.instrument_id;
      }
      if(this.exam.syllabus_id){
        data['syllabus_id']=this.exam.syllabus_id;
      }
      examType
          .checkExamTypeEnabled(data)
          .then((response) => {
            let flag=false;
            flag=response.data.flag;
            if(flag=='true' || flag==true){
              this.getAllExamType();
            }else{
              this.checkOptionTypeEnabled('0');
              this.exam_type_flag=false;
            }

          })
          .catch(err => {

          })
    },
    getAllExamType(){
     /* this.exam.exam_type_id=null*/
      this.exam_types=[];
      this.examTypeLoading=true;
      examType
          .getAllExamType({
            'category_id': this.exam.category_id,
            'instrument_id': this.exam.instrument_id,
            'syllabus_id': this.exam.syllabus_id,
            'grade_id': this.exam.grade_id,
          })
          .then((response) => {
            this.exam_types = response.data.exam_types;
            if(this.exam_types.length === 0 ) {
              this.checkOptionTypeEnabled('0');
              this.exam_type_flag=false;
            }
            if(this.exam_types.length > 0){
              this.exam_type_flag=true;
            }

          })
          .catch(err => {

          }).finally(() => {
        this.examTypeLoading = false;
      });

    },
    changeExamType(emptyField){
      this.findExamTypeDetail(this.exam.exam_type_id);
      if(emptyField){
        this.emptyFields('exam_type');
      }

    },
    findExamTypeDetail(examTypeId){
      examType
          .show(examTypeId)
          .then((response) => {
            this.examTypeDetail=response.data.exam_type;
            if(this.examTypeDetail && this.examTypeDetail.has_exam_options){
              this.exam_option_flag=true;
              this.getAllExamOption();
            }else{
              if(!this.categoryDetail.enable_video_delivery_mode && !this.exam.exam_delivery_method) {
                this.getProductDetail();
              }
              this.exam_option_flag=false;
            }
            this.checkOptionTypeEnabled('1');
          })
          .catch(err => {

          });
    },
    //ExamOption
    checkOptionTypeEnabled(type){
      let data={}
      if(this.exam.category_id){
        data['category_id']=this.exam.category_id;
      }
      if(this.exam.instrument_id){
        data['instrument_id']=this.exam.instrument_id;
      }
      if(this.exam.syllabus_id){
        data['syllabus_id']=this.exam.syllabus_id;
      }
      if(this.exam.syllabus_id){
        data['syllabus_id']=this.exam.syllabus_id;
      }
      if(this.exam.exam_type_id){
        data['exam_type_id']=this.exam.exam_type_id.id;
      }
      data['type']=type;
      examOption
          .checkExamOptionEnabled(data)
          .then((response) => {
            let flag=false;
            flag=response.data.flag;
            if(flag==='true' || flag===true){
              this.getAllExamOption();
            }else{
              this.exam_option_flag=false;
              if(!this.categoryDetail.enable_video_delivery_mode && !this.exam.exam_delivery_method) {
                this.getProductDetail();
              }
            }
          })
          .catch(err => {

          })
    },
    changeExamOption(emptyField=true){
      if(emptyField){
        this.emptyFields('exam_option');
      }
      if(!this.categoryDetail.enable_video_delivery_mode && !this.exam.exam_delivery_method) {
        this.getProductDetail();
      }

    },
    getAllExamOption(){
      this.examOptionLoading = true;
      this.exam_options=[];
      /*this.exam.exam_option_id='';*/
      let data={}
      if(this.exam.category_id){
        data['category_id']=this.exam.category_id;
      }
      if(this.exam.instrument_id){
        data['instrument_id']=this.exam.instrument_id;
      }
      if(this.exam.syllabus_id){
        data['syllabus_id']=this.exam.syllabus_id;
      }
      if(this.exam.exam_option_id){
        data['exam_option_id']=this.exam.exam_option_id;
      }
      if(this.exam.grade_id){
        data['grade_id']=this.exam.grade_id;
      }
      if(this.exam.exam_type_id){
        data['exam_type_id']=this.exam.exam_type_id;
      }
      this.examOptionLoading=false;
      examOption
          .getAllExamOption(data)
          .then((response) => {
            this.exam_options = response.data.exam_options;
            if(this.exam_options.length===0){
              this.exam_option_flag=false;
              if(!this.categoryDetail.enable_video_delivery_mode && !this.exam.exam_delivery_method) {
                this.getProductDetail();
              }
            }
            if(this.exam_options.length > 0){
              this.exam_option_flag=true;
            }
          })
          .catch(err => {

          }).finally(() => {
        this.examOptionLoading = false;
      });

    },
    //Exam session
    getAllExamSession() {
      let data = {
        'location_id':  this.exam.location_id,
        'instrument_id': this.exam.instrument_id,
        'syllabus_id': this.exam.syllabus_id,
        'grade_id': this.exam.grade_id,
        'score_id':this.scoreId,
        'view_closed_exam':this.view_closed_exam,
        'enrolment_year_id':this.session.enrolment_year_id,
        'exam_delivery_method':this.exam.exam_delivery_method,
      };
      examSession
          .getAllExamSession(data)
          .then((response) => {
            this.exam_sessions=response.data.exam_sessions;
          })
          .catch(err => {

          });
    },
    changeExamSession(examSessionId){
      this.getExamSessionDetail(examSessionId,false);
    },
    getExamSessionDetail(examSessionId,flag=false){
      examSession
          .show(examSessionId)
          .then((response) => {
            this.examSessionDetail=response.data.examSession;

            if(!flag){
              this.venueDetail=null;
              this.venue_code=null;
              this.session.can_consider_private_venues="0";
            }

          })
          .catch(err => {

          });
    },
    checkExamSessionClass(enrolmentStage){

      if(enrolmentStage==='closed'){
        return 'alert-danger';
      }else if(enrolmentStage==='running_late'){
        return 'alert-warning';
      }else{
        return 'alert-success';
      }
    },
    checkExamSessionText(enrolmentStage){
      if(enrolmentStage==='closed'){
        return 'Closed';
      }else if(enrolmentStage==='running_late'){
        return 'Late Enrolment';
      }else{
        return 'Open';
      }

    },
    checkExamSessionButtonColor(enrolmentStage){
      if(enrolmentStage==='closed'){
        return 'bg-red-100 text-red-800';
      }else if(enrolmentStage==='running_late'){
        return 'bg-yellow-100 text-yellow-80';
      }else{
        return 'bg-green-100 text-green-800';
      }
    },
    //Product
    getProductDetail(){
      this.productDetail=null;
      let data={};
      if(this.exam.category_id){
        data['category_id']=this.exam.category_id;
      }
      if(this.exam.instrument_id){
        data['instrument_id']=this.exam.instrument_id;
      }
      if(this.exam.syllabus_id){
        data['syllabus_id']=this.exam.syllabus_id;
      }
      if(this.exam.exam_option_id){
        data['exam_option_id']=this.exam.exam_option_id;
      }
      if(this.exam.grade_id){
        data['grade_id']=this.exam.grade_id;
      }
      if(this.exam.exam_type_id){
        data['exam_type_id']=this.exam.exam_type_id;
      }
      if(this.exam.exam_delivery_method){
        data['exam_delivery_method']=this.exam.exam_delivery_method;
      }
      data['is_group_exam']=0;
      this.product_overlay=true;
      product
          .findProductDetail(data)
          .then((response) => {
            if(response.data.message){
              this.messages=response.data.message;
            }
            if(response.data.product) {
              this.productDetail = response.data.product;
              if(!this.categoryDetail.enable_video_delivery_mode){
                this.exam.exam_delivery_method=this.productDetail.exam_delivery_method;
              }
            }
            if(response.data.status==="SUCCESS"){
              this.getProductPriceByScore();
              this.getAllExamSession();
              this.findLocationDetail();

            }else{
              this.$snotify.error(this.messages);
            }
          })
          .catch((err) => {

          }).finally(() => {
        this.product_overlay = false;
      });


    },
    getProductPriceByScore(){
      let data={};
      if(this.productDetail){
        data['product_id']=this.productDetail.id;
        data['score_id']=this.scoreId;
        product
            .getProductPriceByScore(data)
            .then((response) => {
              this.product_price=response.data.product_price;
            })
            .catch(err => {

            });
      }
    },
    scrollValidation(){
      this.$nextTick(() => {
        if(document.querySelector('.input--error-enrolment')){
         document.querySelector('.input--error-enrolment').scrollIntoView();

        }
      })
    },
    createOrUpdate(){
      this.$v.$touch()
      if(this.$v.$error){
        this.scrollValidation()
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
        return false;
      }else {
        let product = {
          'product_id': this.productDetail.id,
          'old_product_price': this.old_product_price,
          'old_product_id': this.oldProductDetail.id,
          'new_product_price': this.product_price
        };
        if(this.productDetail && !this.productDetail.require_recorded_accompaniment){
          this.exam_request.require_recorded_accompaniment = 0;
        }else{
          this.exam_request.require_recorded_accompaniment = this.require_recorded_accompaniment;
        }

        if(this.productDetail && this.productDetail.require_teaching_grade){
          this.exam_request.teaching_grades = this.teaching_grades;
        }else{
          this.exam_request.teaching_grades = '';
        }

        if(this.scoreDetail && this.scoreDetail.enable_private_venues && this.examSessionDetail && !this.examSessionDetail.enable_private_venue){
          this.session.venue_id=null;
          this.session.venue_code=null;
        }else{
          this.session.can_consider_private_venues="0";
        }
        let data = {'exam': this.exam, 'exam_session': this.session, 'product': product,'exam_request':this.exam_request};
        
        candidate
            .updateExamEnroled(this.candidateId, data)
            .then((response) => {
              this.$emit('refresh');
              if(response.data.status==='SUCCESS'){
                this.$snotify.success(response.data.message);
              }
              if(response.data.status==='ERROR'){
                this.$snotify.error(response.data.message);
              }
              this.closeDialog();
            })
            .catch(err => {

            });
      }

    },

    emptyFields(type) {
      if (type === 'all') {
        this.exam.location_id = '';
        this.exam.category_id = '';
        this.exam.instrument_id = '';
        this.exam.syllabus_id = '';
        this.exam.exam_type_id = '';
        this.exam.exam_option_id = '';
        this.exam.grade_id = '';
        this.exam.exam_delivery_method = '';
        this.exam.hand_type = '';
        this.grade_ids = null;
        this.productDetail = null;
        this.subject_code = '';
        this.subjectCodeStatus = null;
        this.exam_request={};
        this.categories=[];
        this.instruments=[];
        this.syllabuses=[];
        this.grades=[];
        this.exam_options=[];
        this.exam_types=[];
        this.grade_ids=[];
        this.scheduling_requests=[];
        this.candidate_enrolment_info=null;
        this.accountHolderDetail=null;
        this.scoreDetail=null;
        this.categoryDetail=null;
        this.instrumentDetail=null;
        this.syllabusDetail=null;
        this.gradeDetail=null;
        this.examTypeDetail=null;
        this.examOptionDetail=null;
        this.productDetail=null;
        this.oldProductDetail=null;
        this.examSessionDetail=null;
        this.venue_code=null;
        this.venueDetail=null;
        this.venue_messages=null;
        this.venueStatus=null;
        this.currency=null;
        this.subjectCodeSearch=false;
        this.subject_code=null;
        this.subject_messages=null;
        this.subjectCodeStatus=null;
      }
      if (type === 'location') {
        this.exam.category_id = '';
        this.exam.instrument_id = '';
        this.exam.syllabus_id = '';
        this.exam.exam_type_id = '';
        this.exam.exam_option_id = '';
        this.exam.grade_id = '';
        this.exam.hand_type = '';
        this.exam.exam_delivery_method = '';
        this.grade_ids = null;
        this.productDetail = null;
        this.subject_code = '';
        this.subjectCodeStatus = null;
        this.exam_request={};
      }
      if (type === 'category') {
        this.exam.instrument_id = '';
        this.exam.syllabus_id = '';
        this.exam.exam_type_id = '';
        this.exam.exam_option_id = '';
        this.exam.grade_id = '';
        this.exam.exam_delivery_method = '';
        this.exam.hand_type = '';
        this.grade_ids = null;
        this.productDetail = null;
        this.subject_code = '';
        this.subjectCodeStatus = null;
        this.exam_request={};
      }

      if (type === 'instrument') {
        this.exam.syllabus_id = '';
        this.exam.exam_type_id = '';
        this.exam.exam_option_id = '';
        this.exam.grade_id = '';
        this.exam.hand_type = '';
        this.grade_ids = null;
        this.productDetail = null;
        this.subject_code = '';
        this.subjectCodeStatus = null;
        this.exam_request={};
      }

      if (type === 'syllabus') {
        this.exam.exam_type_id = '';
        this.exam.exam_option_id = '';
        this.exam.grade_id = '';
        this.exam.hand_type = '';
        this.grade_ids = null;
        this.productDetail = null;
        this.subject_code = '';
        this.subjectCodeStatus = null;
        this.exam_request={};
      }

      if (type === 'grade') {
        this.exam.exam_type_id = '';
        this.exam.exam_option_id = '';
        this.exam.hand_type = '';
        this.grade_ids = null;
        this.productDetail = null;
        this.subject_code = '';
        this.subjectCodeStatus = null;
        this.exam_request={};
      }

      if (type === 'exam_type') {
        this.exam.exam_option_id = '';
        this.exam.hand_type = '';
        this.productDetail = null;
        this.subject_code = '';
        this.subjectCodeStatus = null;
        this.exam_request={};
      }

      if (type === 'exam_option') {
        this.exam.hand_type = '';
        this.grade_ids = null;
        this.productDetail = null;
        this.subject_code = '';
        this.subjectCodeStatus = null;
        this.exam_request={};
      }
    },
    viewClosedExam(){
      this.getAllExamSession();
    },
    //Venue Cart
    verifyVenueCode(){
      venue
          .verifyVenueCode({'venue_code':this.venue_code,'score_id':this.scoreDetail.id})
          .then((response) => {
            this.venue_messages=response.data.message;
            this.venueStatus=response.data.status;
            if(this.venueStatus==="SUCCESS"){

            }else{
              this.buttonDisabled=true;
              this.venue_code=null;
              this.venueDetail= null;
              this.session.venue_id= null;
            }
            if(response.data.venue){
              this.venueDetail=response.data.venue;
              this.session.venue_id=this.venueDetail.id;
              this.session.venue_code=this.venue_code;
            }

          })
          .catch(err => {
            // console.log(err)
          }).finally(() => {

      });
    },
    changeSubjectCode(){
      this.resetData();
    },
    searchBySubjectCode(){
      let data={};
      if(this.subject_code){
        data['subject_code']=this.subject_code;
        data['is_group_exam']=0;
        data['score_id']=this.scoreId;

        product
            .searchBySubjectCode(data)
            .then((response) => {
              this.subject_messages='We can\'t find a match for that today. Please try again.';
              this.subjectCodeStatus=response.data.status;
              if(this.subjectCodeStatus==="SUCCESS"){
                if(response.data.product){
                  this.productDetail=response.data.product;
                  if(this.productDetail){
                    if(this.productDetail.category_id){
                      this.exam.category_id=this.productDetail.category_id;
                    }
                    if(this.productDetail.instrument_id){
                      this.exam.instrument_id=this.productDetail.instrument_id;
                    }

                    if(this.productDetail.syllabus_id){
                      this.exam.syllabus_id=this.productDetail.syllabus_id;
                    }

                    if(this.productDetail.grade_id){
                      this.exam.grade_id=this.productDetail.grade_id;
                    }
                    if(this.productDetail.exam_delivery_method){
                      this.exam.exam_delivery_method=this.productDetail.exam_delivery_method;
                    }
                  }
                  this.getAllExamSession();
                  this.getProductPriceByScore();
                }
              }
              if(this.subjectCodeStatus==='ERROR'){
                this.subject_code=null;
                this.productDetail=null;
              }
              setTimeout(() => {
                this.subject_messages=null;
                this.subjectCodeStatus=null;
              }, 3000);
            })
            .catch(err => {
              // console.log(err)
            })
            .finally(() => {

            });
      }
    },
    resetData(){
      this.exam.exam_delivery_method=null;
      this.exam.category_id=null;
      this.exam.syllabus_id=null;
      this.exam.grade_id=null;
      this.exam.exam_type_id=null;
      this.exam.exam_option_id=null;
      this.exam.hand_type=null;
      this.exam.guitar_selection=null;
      this.exam.drum_selection=null;
      this.exam.can_consider_electric_drum_kit=null;
      this.exam.collaborative_instrument=null;
      this.session.exam_session_id=null;
      this.session.can_consider_private_venues=null;
      this.categoryDetail=null;
      this.instrumentDetail=null;
      this.syllabusDetail=null;
      this.gradeDetail=null;
      this.examTypeDetail=null;
      this.examOptionDetail=null;
      this.productDetail=null;
      this.venueDetail=null;
      this.scoreDetail=null;
      this.schedulingRequestDetail=null;
      this.examSessionDetail=null;
      this.instrument_flag=false;
      this.syllabus_flag=false;
      this.exam_type_flag=false;
      this.exam_option_flag=false;
      this.preferred_pronouns_flag=false;
      this.$v.$reset();
      this.subject_code='';
      this.subject_messages='';
      this.subjectCodeStatus=null;
      this.exam_sessions=[];
    },
  },
  computed: {
    rules() {
      return {
        subject_code: {
          required: requiredIf(function () {
            return this.subjectCodeSearch;
          })
        },
        exam: {
          location_id: {required},
          category_id: {required},
          instrument_id: {
            required: requiredIf(function () {
              return this.instrument_flag && !this.subjectCodeSearch;
            })
          },
          exam_delivery_method: {
            required: requiredIf(function () {
              return this.categoryDetail && this.categoryDetail.enable_video_delivery_mode && !this.subjectCodeSearch;
            })
          },
          syllabus_id: {
            required: requiredIf(function () {
              return this.syllabus_flag && !this.subjectCodeSearch;
            })
          },
          exam_type_id: {
            required: requiredIf(function () {
              return this.exam_type_flag && !this.subjectCodeSearch;
            })
          },
          exam_option_id: {
            required: requiredIf(function () {
              return this.exam_option_flag && !this.subjectCodeSearch;
            })
          },
          grade_id: {
            required: requiredIf(function () {
              return !this.subjectCodeSearch;
            })
          },
          hand_type: {
            required: requiredIf(function () {
              return this.productDetail && this.productDetail.requires_hand_selection;
            })
          },
          guitar_selection: {
            required: requiredIf(function () {
              return this.productDetail && this.productDetail.requires_guitar_selection;
            })
          },
          drum_selection: {
            required: requiredIf(function () {
              return this.productDetail && this.productDetail.requires_drum_selection;
            })
          },
        },
        session:{
          exam_session_id:{required},
          enrolment_year_id:{required},
        },
        venueDetail: {
          required: requiredIf(function () {
            return this.scoreDetail && this.scoreDetail.enable_private_venues && this.examSessionDetail && this.examSessionDetail.enable_private_venue && this.examSessionDetail.is_private_venue_mandatory===true;
          })
        },
        exam_request:{

        },
      }
    }
  },
}
</script>