import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class CandidateEnrolmentInfoService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/candidate-enrolment-info';
    }

    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }

    paginate(data = {}, index = null) {

        let url = this.#api;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                if (data[key] && data[key] != null)
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        if (index != null && !queryString)
            url = url + '?' + "page=" + (index);
        else
            url = url + '&' + "page=" + (index);
        return apiService.get(url);
    }
    update(id,data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url,data)
    }
    delete(id){
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
    findByCandidateId(data={}){
        let url = `${this.#api}/find-by-category`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    markAwardAsSubmitted(enrolmentInfoId){
        let url = `${this.#api}/${enrolmentInfoId}/mark-as-submitted`
        return apiService.post(url)
    }
    markAwardAsNotSubmitted(enrolmentInfoId){
        let url = `${this.#api}/${enrolmentInfoId}/mark-as-not-submitted`
        return apiService.post(url)
    }
    markAwardAsVerified(enrolmentInfoId){
        let url = `${this.#api}/${enrolmentInfoId}/mark-as-verified`
        return apiService.post(url)
    }
    markAwardAsRejected(enrolmentInfoId, data){
        let url = `${this.#api}/${enrolmentInfoId}/mark-as-rejected`
        return apiService.post(url, data)
    }
    updateSubmissionMethod(enrolmentInfoId, data){
        let url = `${this.#api}/${enrolmentInfoId}/update/award-submission-method`
        return apiService.put(url,data)
    }
    uploadAwardLogBook(data, enrolmentInfoId){
        let url = `${this.#api}/${enrolmentInfoId}/upload/award-log-book`
        return apiService.post(url,data)
    }
    deleteAwardLogBook(enrolmentInfoId){
        let url = `${this.#api}/${enrolmentInfoId}/delete/award-log-book`
        return apiService.delete(url);
    }
    deleteAwardReportFile(enrolmentInfoId){
        let url = `${this.#api}/${enrolmentInfoId}/delete/award-report-file`
        return apiService.delete(url);
    }
}